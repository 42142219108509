export const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export const STORE_API = 'https://store.xsolla.com/api';
export const SANDBOX_SECURE_HOST = 'sandbox-secure.xsolla.com';
export const SECURE_HOST = 'secure.xsolla.com';
export const STORE_CART_ID = 'team-17-store-cart';
export const STORE_PROJECT_ID = '36867';
export const AIRTABLE_BASE = 'appizFApblnkAyMwz';
export const AIRTABLE_URL = `https://api.airtable.com/v0/${AIRTABLE_BASE}`;
export const AIRTABLE_APIKEY = 'pat66giqUJb47PNMY.6d2432ff568d027d54703040780229ce0a97c05d3c51e77756e29a555ca79a8e';
export const USER_ID_LOCAL_STORAGE_KEY = 'team17-store-user-id';
export const ANALYTICS_TAG = '$pSB$c9048';

export const XSOLLA_LOGIN_TOKEN_SB = 'xsolla_login_token_sb';
export const LOGIN_API = 'https://login.xsolla.com/api';
export const PROJECT_ID = '3c7e586f-ac17-11ea-a85b-42010aa80004';
export const PREFERRED_LOCALE = 'en_US';
export const LOGIN_CALLBACK_URL = 'https://store.team17.com/';
export const LOGIN_CALLBACK_URL_LOCALE = 'http://localhost:3000/';

export const LAUNCHER_HOST = 'https://launcher.xsolla.com';
export const PROJECT_ID_NEWS = '38639264-38e7-489b-882a-ed5e9aec901c';
export const NUMBER_OF_NEWS_PER_REQUEST = 3;
export const MAX_NUMBER_OF_NEWS_PER_PAGE = 9;
export const ANALYTIC_COUNTER_ID = 9048;
export const MERCHANT_ID = 67256;
